import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M6260 7603 c-103 -17 -272 -93 -340 -153 -14 -12 -36 -32 -50 -44
-55 -47 -170 -203 -170 -230 0 -3 35 -6 79 -6 l78 0 28 41 c43 60 133 140 203
177 98 53 169 73 272 79 205 12 401 -76 530 -240 l45 -57 78 0 c43 0 77 3 75
8 -28 63 -69 122 -127 184 -158 169 -344 249 -571 247 -58 -1 -116 -4 -130 -6z"/>
<path d="M6261 7425 c-131 -30 -269 -115 -343 -212 -40 -54 -78 -114 -78 -125
0 -5 25 -8 56 -8 l57 0 61 67 c113 123 227 176 381 176 156 0 271 -54 382
-178 l58 -65 58 0 c31 0 57 2 57 5 0 29 -86 145 -148 200 -55 49 -152 104
-222 126 -84 27 -232 34 -319 14z"/>
<path d="M6277 7280 c-108 -27 -244 -129 -295 -219 l-23 -41 46 0 c42 0 49 4
103 58 79 81 151 114 257 120 92 5 159 -12 230 -56 38 -24 114 -100 115 -114
0 -11 100 -10 100 0 0 5 -17 33 -38 63 -46 66 -141 140 -219 169 -66 25 -213
36 -276 20z"/>
<path d="M6345 7108 c-75 -26 -119 -96 -113 -178 4 -61 24 -95 73 -128 72 -46
144 -39 211 22 36 33 47 49 51 83 12 91 -24 159 -102 194 -45 21 -76 22 -120
7z"/>
<path d="M5734 6521 c-2 -2 -4 -370 -4 -818 l0 -814 128 3 127 3 0 810 0 810
-123 5 c-68 3 -126 3 -128 1z"/>
<path d="M6016 6512 c-3 -5 -4 -60 -3 -123 l2 -114 520 0 520 0 0 120 0 120
-517 3 c-284 1 -519 -2 -522 -6z"/>
<path d="M6010 5820 l0 -380 405 0 405 0 0 -150 0 -150 -405 0 -405 0 0 -125
0 -125 518 2 517 3 3 387 2 388 -392 2 -393 3 -3 138 -3 137 391 0 390 0 0
125 0 125 -515 0 -515 0 0 -380z"/>
<path d="M6782 5813 l3 -108 128 -3 127 -3 0 111 0 110 -130 0 -131 0 3 -107z"/>
<path d="M6180 4300 l0 -170 30 0 30 0 0 170 0 170 -30 0 -30 0 0 -170z"/>
<path d="M6317 4463 c-4 -3 -7 -80 -7 -170 l0 -163 30 0 30 0 0 170 0 170 -23
0 c-13 0 -27 -3 -30 -7z"/>
<path d="M7242 4298 c3 -148 5 -172 18 -169 8 2 31 3 50 2 107 -7 121 -4 154
28 28 28 31 37 31 92 0 56 -3 63 -32 89 -38 34 -87 46 -128 30 -17 -6 -32 -10
-35 -8 -2 2 -5 26 -5 53 0 47 -2 50 -28 53 l-27 3 2 -173z m172 11 c51 -40 21
-139 -43 -139 -38 0 -71 37 -71 79 1 72 59 103 114 60z"/>
<path d="M5539 4443 c-1 -5 -2 -77 -3 -160 -1 -139 1 -153 17 -153 9 0 20 -1
25 -2 4 -2 9 47 12 107 l5 110 85 -107 c70 -88 90 -107 113 -107 l27 -1 0 160
0 160 -30 0 -29 0 -3 -106 -3 -107 -85 107 c-63 78 -91 106 -107 106 -13 0
-24 -3 -24 -7z"/>
<path d="M5943 4359 c-101 -50 -89 -193 20 -228 68 -23 148 5 133 45 -4 12
-13 14 -29 9 -52 -16 -78 -17 -102 -1 -14 9 -25 23 -25 31 0 12 17 15 89 15
50 0 93 4 96 10 9 14 -12 73 -34 99 -17 19 -68 41 -96 41 -5 0 -29 -9 -52 -21z
m107 -49 c11 -11 20 -24 20 -30 0 -12 -127 -14 -134 -2 -3 5 4 19 15 30 25 28
73 28 99 2z"/>
<path d="M6730 4367 c-46 -16 -68 -56 -52 -91 12 -28 32 -39 97 -52 30 -7 40
-14 40 -29 0 -16 -8 -21 -42 -23 -23 -2 -52 2 -64 8 -17 10 -23 9 -30 -1 -16
-25 -9 -37 27 -49 81 -27 174 11 174 70 0 37 -26 57 -89 71 -91 19 -78 64 15
55 41 -5 53 -3 58 10 9 23 9 22 -31 33 -47 13 -59 13 -103 -2z"/>
<path d="M6413 4338 c8 -18 31 -71 51 -116 20 -46 36 -92 36 -102 0 -26 -25
-42 -62 -38 -27 2 -34 -1 -36 -18 -3 -22 19 -34 65 -34 33 0 79 48 101 105 11
28 38 92 60 143 l40 92 -27 0 c-24 0 -30 -8 -62 -82 -20 -46 -39 -83 -44 -83
-4 0 -22 36 -40 80 -30 75 -34 80 -64 83 l-32 3 14 -33z"/>
<path d="M6930 4277 c0 -106 10 -129 66 -147 28 -9 46 -10 74 -1 25 7 47 8 63
2 14 -5 28 -7 31 -4 3 4 6 60 6 125 l0 118 -30 0 -30 0 0 -79 c0 -86 -9 -105
-57 -117 -45 -12 -66 22 -71 115 -4 77 -6 81 -28 81 -24 0 -24 -2 -24 -93z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
